<script setup lang="ts">
const structureArray = [
  {
    size: 'big',
    images: [
      '/imgs/screenshots/atlantis-home-300.jpg',
      '/imgs/screenshots/atlantis-barbie-300.jpg',
      '/imgs/screenshots/oakwood-home-300.jpg',
    ],
  },
  {
    size: 'small',
    images: [

      '/imgs/screenshots/poweruppicks-home-500.jpg',
      '/imgs/screenshots/kimpton-home-500.jpg',
      '/imgs/screenshots/npiwff-home-500.jpg',
      '/imgs/screenshots/voix-home-500.jpg',
      '/imgs/screenshots/paranza-home-500.jpg',
      '/imgs/screenshots/atlantis-home-300.jpg',
      '/imgs/screenshots/alltra-app-500.jpg',

    ],
  },
  {
    size: 'big',
    images: [
      '/imgs/screenshots/atlantis-home-300.jpg',
      '/imgs/screenshots/poweruppicks-home-500.jpg',
      '/imgs/screenshots/playa-home-500.jpg',
      '/imgs/screenshots/kimpton-home-500.jpg',
      '/imgs/screenshots/atlantis-barbie-300.jpg',
      '/imgs/screenshots/atlantis-newyears-500.jpg',
      '/imgs/screenshots/sanctuary-app-500.jpg',
    ],
  },
  {
    size: 'small',
    images: [
      '/imgs/screenshots/atlantis-newyears-500.jpg',
      '/imgs/screenshots/sanctuary-app-500.jpg',
      '/imgs/screenshots/oakwood-home-300.jpg',
      '/imgs/screenshots/voix-home-500.jpg',
      '/imgs/screenshots/npiwff-home-500.jpg',
      '/imgs/screenshots/atlantis-barbie-300.jpg',
      '/imgs/screenshots/poweruppicks-home-500.jpg',
      '/imgs/screenshots/playa-home-500.jpg',
      '/imgs/screenshots/kimpton-home-500.jpg',
      '/imgs/screenshots/atlantis-home-300.jpg',
    ],
  },
  {
    size: 'big',
    images: [
      '/imgs/screenshots/npiwff-home-500.jpg',
      '/imgs/screenshots/atlantis-barbie-300.jpg',
      '/imgs/screenshots/atlantis-home-300.jpg',
      '/imgs/screenshots/alltra-app-500.jpg',
      '/imgs/screenshots/atlantis-newyears-500.jpg',
      '/imgs/screenshots/kimpton-home-500.jpg',
      '/imgs/screenshots/oakwood-home-300.jpg',
      '/imgs/screenshots/paranza-home-500.jpg',
      '/imgs/screenshots/playa-home-500.jpg',
      '/imgs/screenshots/npiwff-home-500.jpg',
    ],
  },
  {
    size: 'small',
    images: [

      '/imgs/screenshots/atlantis-newyears-500.jpg',
      '/imgs/screenshots/playa-home-500.jpg',
      '/imgs/screenshots/atlantis-barbie-300.jpg',
      '/imgs/screenshots/kimpton-home-500.jpg',
      '/imgs/screenshots/npiwff-home-500.jpg',
      '/imgs/screenshots/poweruppicks-home-500.jpg',
      '/imgs/screenshots/paranza-home-500.jpg',
      '/imgs/screenshots/atlantis-home-300.jpg',
      '/imgs/screenshots/voix-home-500.jpg',
      '/imgs/screenshots/oakwood-home-300.jpg',

    ],
  },
  {
    size: 'big',
    images: [

      '/imgs/screenshots/paranza-home-500.jpg',
      '/imgs/screenshots/poweruppicks-home-500.jpg',
      '/imgs/screenshots/atlantis-home-300.jpg',
      '/imgs/screenshots/kimpton-home-500.jpg',
      '/imgs/screenshots/voix-home-500.jpg',
      '/imgs/screenshots/npiwff-home-500.jpg',
      '/imgs/screenshots/alltra-app-500.jpg',
      '/imgs/screenshots/atlantis-barbie-300.jpg',
    ],
  },
]
</script>

<template>
  <div class="relative flex space-x-2 space-y-4">
    <div v-for="(column, n) in structureArray" :key="n" class="flex flex-col space-y-2 first:mt-4">
      <div
        v-for="(image, i) in column.images" :key="i" class="bg-stone-800 rounded overflow-hidden" :class="{
          'w-[300px] h-[400px]': column.size === 'big',
          'w-[200px] h-[280px]': column.size === 'small',
        }"
      >
        <NuxtImg :src="image" class="w-full h-full object-cover" format="webp" provider="ipx" :modifiers="{ q: 90, f: 'webp' }" :width="column.size === 'big' ? 300 : 200" alt="Screenshot of a project - just background imagery" />
      </div>
    </div>

    <div class="absolute left-0 right-0 bottom-0 h-[50vh] bg-gradient-to-t from-stone-950 via-stone-950/0" />
  </div>
</template>

<style scoped>

</style>
