<script setup lang="ts">
import type Lenis from 'lenis'
import { useScrollLock } from '@vueuse/core'
import { animate, scroll } from 'motion'
import { defineAsyncComponent, hydrateOnIdle, hydrateOnInteraction } from 'vue'

const el = ref<HTMLElement | null>(null)
const showAreWeFit = ref(false)
const isLocked = useScrollLock(el)

const contents = ref<HTMLElement | null>(null)
const contentsFixed = ref(true)
const container = ref<HTMLElement | null>(null)
const mosaic = ref<HTMLElement | null>(null)

const lenis = inject<Ref<null | Lenis>>('lenis')
const mounted = ref(false)

const LazyAFAreWeAFit = defineAsyncComponent({
  loader: () => import('./AreWeAFit.vue'),
  hydrate: hydrateOnInteraction(),
})

onMounted(() => {
  el.value = document.body as HTMLElement
  mounted.value = true

  scroll(
    ({ y }) => {
      if (y.progress < 1) {
        contentsFixed.value = true
      }
      else {
        contentsFixed.value = false
      }
    },
    {
      target: container.value,
      offset: ['start start', 'end end'],
    },
  )

  scroll(
    animate(mosaic.value, { opacity: [0.10, 1], filter: ['saturate(0) blur(20px)', 'saturate(1) blur(0)'], transform: ['scale(1.25) translateY(20vh)', 'scale(1)'] }),
    {
      target: container.value,
      offset: ['start start', 'end end'],
    },
  )
})

watchEffect(() => {
  if (showAreWeFit.value) {
    isLocked.value = true
    lenis?.value?.stop()
  }
  else {
    isLocked.value = false
    lenis?.value?.start()
  }
})

async function onEnter(el: Element, done: () => void) {
  const hel = el as HTMLElement
  await animate(hel, { opacity: 1 }, { duration: 0.7 }).finished
  done()
}

async function onLeave(el: Element, done: () => void) {
  const hel = el as HTMLElement
  await animate(hel, { opacity: 0 }, { duration: 0.5 }).finished
  done()
}
</script>

<template>
  <div id="welcome" ref="container" class="w-full h-[calc(200vh-65px)] overflow-hidden">
    <div class="relative bg-stone-950 h-[calc(200vh-65px)] flex items-end">
      <div class="pointer-events-none absolute z-10 left-12 top-1/2 -translate-y-full transform-gpu blur-3xl scale-125 lg:top-[-12rem] lg:bottom-auto lg:translate-y-0 lg:transform-gpu" aria-hidden="true">
        <div class="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-teal-500 to-pink-500 opacity-50" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
      </div>

      <div class="absolute z-0 inset-0 blur-2xl opacity-50">
        <video
          autoplay
          loop
          muted
          playsinline
          class="object-cover w-full h-full"
          src="/assets/videos/underwater-paint-clouds-small.mp4"
        />
      </div>

      <div class="pointer-events-none absolute z-10 left-12 top-1/2 -translate-y-full transform-gpu  scale-125 blur-3xl lg:bottom-[-12rem] lg:top-auto lg:translate-y-0 lg:transform-gpu" aria-hidden="true">
        <div class="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-25" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
      </div>

      <div class="pointer-events-none absolute z-0 right-12 bottom-1/2 translate-y-full transform-gpu blur-3xl lg:bottom-[-12rem] lg:top-auto lg:translate-y-0 lg:transform-gpu" aria-hidden="true">
        <div class="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-orange-500 to-teal-500 opacity-25" style="clip-path: polygon(20.1% 20.1%, 0% 20.6%, 0.5% 84.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
      </div>

      <div class="absolute z-0 inset-0">
        <div ref="mosaic" class="flex justify-center will-change-transform transform-gpu">
          <GraphicsSitesWeBuiltMosaic class="w-screen duration-1000" :class="{ 'opacity-0': !mounted, 'opacity-100': mounted }" />
        </div>

        <div class="absolute inset-0 bg-gradient-to-tr from-stone-950 via-stone-950 via-30% to-stone-950/0" />
      </div>

      <div
        ref="contents" class="w-full z-10 flex items-end justify-between " :class="{
          'fixed bottom-0 left-0': contentsFixed,
          'relative': !contentsFixed,
        }"
      >
        <div class="relative z-50 isolate overflow-hidden px-12 pb-12 lg:pb-24 lg:pl-24 lg:pr-0">
          <div class="max-w-2xl">
            <div class="hidden sm:inline-block">
              <div class="relative rounded-full px-3 py-1 text-sm leading-6 text-stone-400 ring-1 ring-white/10 hover:ring-white/20">
                Enterprise web development for applications and marketing
              </div>
            </div>
            <div class="mt-4">
              <LbmLogo class="w-[80px] text-stone-200" mode="logo" direction="horizontal" animate-on-hover />
              <h1 class="flex items-center space-x-3">
                <div class="text-3xl font-lbm-sans font-extrabold uppercase tracking-tight text-white sm:text-7xl">
                  It's time to build something <span class="whitespace-nowrap">special ....</span>
                </div>
              </h1>
              <p class="hidden sm:block pt-4 text-xl text-stone-300 font-light font-lbm-serif">
                On behalf of our team we are so happy to have you here. We know that finding a development team that can speak your language is hard. Logicbomb Media is a team of developers that will shoot you straight and determine if our skill sets are a great fit for your project.
              </p>
              <div class="mt-10  font-sans font-bold text-xs">
                <button
                  class="inline-flex flex-col lg:flex-row py-2 uppercase animate-shimmer text-left lg:items-center justify-center rounded-full border border-stone-800 bg-[linear-gradient(110deg,#000103,45%,#1e2631,55%,#000103)] bg-[length:200%_100%] px-6 text-stone-300 transition-colors focus:outline-none focus:ring-2 focus:ring-stone-400 focus:ring-offset-2 focus:ring-offset-stone-50"
                  @click="showAreWeFit = true"
                >
                  <span class="text-orange-400 pr-2">Learn More:</span><span> Who are we and what can we do?</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Transition
        :css="false"
        @enter="onEnter"
        @leave="onLeave"
      >
        <LazyLbmAreWeAFit v-if="showAreWeFit" @close="showAreWeFit = false" />
      </Transition>
    </div>
  </div>
</template>
